import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    Divider,
    Dialog,
    Grid,
    TextField,
    Radio,
    FormControlLabel,
    Backdrop,
    CircularProgress
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    ElementsConsumer
} from '@stripe/react-stripe-js';

// Customizable Area End

// Customizable Area Start
import ListingCheckOutPageController, {
    Props, configJSON,
} from "./ListingCheckOutPageController";
import { logoImage, searchIcon, dummyIcon, downArrow, cardIcon2, closeIcon, rightTickIcon, deleteImage } from "./assets";
import AppBarPage from "../../../components/src/AppBar.web";

const stripePromise = loadStripe('pk_test_51Nmze6IePQ7KZBtF4T1TWd9AuveLbIuDY8is1lYOT3plAU7GTLlNOmJYQtZbVHq5tcfvoM7Js8MuBoCqBNCvURI700hqKvZoKX');
// Customizable Area End

export class ListingCheckOutPage extends ListingCheckOutPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { selectedPlan, taxValue, couponValue } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.root}>
                <Backdrop className={classes.backdrop} open={this.state.isLoader}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBarPage
                    handleClick={this.goToLoginPage}
                    logoImg={logoImage}
                    serachIcon={searchIcon}
                    username={this.state.Username}
                    dummyicon={dummyIcon}
                    downarrow={downArrow}
                    goToHomePage={this.goToLandingScreen}
                    handleHeaderNavigation={this.handleHeaderNavigation}
                    handleUserSignout={this.signoutUser}
                    handleAdvancedSearch={this.handleAdvancedSearchNavigation}
                    handleSubmit={this.handleSubmitSearch}
                        showDirectoryPopup={this.state.showDirectoryPopup}
                        searchDirectoryData={this.state.searchDirectoryData}
                        handleSearchInput={this.handleSearchInput}  
                    data-testId={"signInBtn"}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />

                <Box className={classes.detailsBoxStyles}>
                    <Typography className={classes.detailsTextStyle}>{configJSON.details}</Typography>
                    <Grid container justifyContent="center" alignItems="center" className={classes.gridContainerStyle}>
                        <Grid item xs={12} md={6} lg={6} >
                            <Box className={classes.boxStyle1}>
                                <Box className={classes.detailsBox}>
                                    <Typography className={classes.headingStyle}>
                                        {this.state.selectedPlan?.selectedPlan.directory_Name} -
                                        {' '}
                                        {this.state.selectedPlan?.selectedPlan?.plan?.name &&
                                            this.state.selectedPlan.selectedPlan.plan.name
                                                .toLowerCase()
                                                .replace(/\b\w/g, l => l.toUpperCase())
                                        }
                                    </Typography>
                                </Box>
                                <Divider style={{ backgroundColor: "#D9D9D9" }} />
                                <Box className={classes.contentBoxStyle}>
                                    <Typography className={classes.headingStyle1}>{this.state.selectedPlan?.selectedPlan.plan.description}</Typography>
                                    <Typography className={classes.headingStyle1}>{this.state.listName}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Box className={classes.boxStyle2}>
                                <Box className={classes.totalBoxStyle}>
                                    <Typography className={classes.totalPriceTextStyle}>{configJSON.subTotal}</Typography>
                                    <Typography className={classes.totalPriceTextStyle}>$ {selectedPlan?.selectedPlan?.plan?.price}</Typography>
                                </Box>

                                <Box className={classes.totalBoxStyle2}>
                                    <Typography className={classes.totalPriceTextStyle2}>{configJSON.tax}</Typography>
                                    <Typography className={classes.totalPriceTextStyle2} data-testid={"taxvalue"}>${taxValue}</Typography>
                                </Box>

                                {this.state.isCouponCode ?
                                    <>
                                        <Box className={classes.cuponBoxStyle}>
                                            <Typography className={classes.cuponTextStyle}>{this.state.couponText}</Typography>
                                            <Typography data-test-id="removeCoupon" className={classes.removeTextStyle} onClick={this.handleRemoveCoupon}>{configJSON.remove}</Typography>
                                        </Box>

                                        <Box className={classes.cuponDiscpuntBox}>
                                            <Typography className={classes.cuponTextStyle2}>{configJSON.cupondiscount}</Typography>
                                            <Typography className={classes.cuponTextStyle2}>-${this.state.currentDiscount}</Typography>
                                        </Box>
                                    </>
                                    :
                                    <>
                                     <Box className={classes.cuponBoxStyle} style={{border:"unset"}}>
                                        <TextField
                                            placeholder={"Enter coupon code"}
                                            data-testid={"coup-code"}
                                            onChange={this.handleChangeCoupon}
                                            helperText={<Typography className={classes.errorStyle}>{this.state.errorCouponCode}</Typography>}
                                            value={this.state.couponText}
                                            style={{width:"100%"}}
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                            InputProps={{
                                                style: {
                                                    borderBottom: '1px solid #9B9B9B',
                                                    fontSize: '15px',
                                                    fontWeight: 400,
                                                    fontFamily: 'Poppins',
                                                    margin: "0px 5%",
                                                    width: "90%",
                                                },
                                                endAdornment: (
                                                    <Button variant="contained" color="primary" onClick={this.handleApplyCouponCode}>
                                                        Apply
                                                    </Button>
                                                ),
                                            }}
                                            className={classes.margin}
                                        />
                                        </Box>
                                    </>
                                }
                                <Box className={classes.totalBoxStyle3}>
                                    <Typography className={classes.totalPriceTextStyle3}>{configJSON.totalValue}</Typography>
                                    <Typography className={classes.totalPriceTextStyle3}>${this.state.totalAmount}</Typography>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item xs={11} md={11} lg={11} className={classes.gridItemStyle} >
                            <Elements stripe={stripePromise}  >
                                <ElementsConsumer >
                                    {({ elements, stripe }: any) => (
                                        <form onSubmit={(e) => this.SubmitPaymentButton(e, elements, stripe)}>
                                            <Box className={classes.paymentBoxStyle}>
                                                <Box className={classes.boxStyle}>
                                                    <Typography className={classes.paymentText}>{configJSON.paymentOption}</Typography>
                                                    <Divider className={classes.dividerStyle} />
                                                    <Box>
                                                        <Box className={classes.cardBoxStyle}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        className={classes.root1}
                                                                        disableRipple
                                                                        color="default"
                                                                        icon={<span className={classes.icon} />}
                                                                        checkedIcon={<span className={classes.checkedIcon} />}
                                                                        value="card"
                                                                        data-testid={"card"}
                                                                        checked={this.state.isCardCheck === 'card'}
                                                                        onChange={() => this.handleCardCheck("card")}
                                                                    />
                                                                }
                                                                label={
                                                                    <Box className={classes.labelBoxStyle}>
                                                                        <span className={classes.label3Font}>{configJSON.card}</span>
                                                                        <img src={cardIcon2} alt="Card" className={classes.cardIconStyle2} />
                                                                    </Box>
                                                                }
                                                                style={{ flex: 1 }}
                                                            />
                                                        </Box>

                                                        <Box className={classes.textfeildBox}>
                                                            <TextField
                                                                placeholder={configJSON.entername}
                                                                value={this.state.enterName}
                                                                onChange={this.handleChangeName}
                                                                data-testid={"editCardName"}
                                                                className={classes.nameTextfield}
                                                                helperText={<Typography className={classes.errorStyle}>{this.state.nameErrorMessage}</Typography>}
                                                                InputProps={{
                                                                    disableUnderline:true,
                                                                    style: {
                                                                        borderBottom: '1px solid #DDDDDD',
                                                                        fontSize: '15px',
                                                                        fontWeight: 400,
                                                                        fontFamily: 'Poppins'
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: false,
                                                                }}
                                                            />
                                                            <LandingPageStyle>
                                                                <CardNumberElement />

                                                            </LandingPageStyle>
                                                            <Box className={classes.cvvBoxStyle}>
                                                                <LandingPageStyle style={{ width: "45%", }}>
                                                                    <CardCvcElement />
                                                                </LandingPageStyle>
                                                                <LandingPageStyle style={{ width: "54%", }}>
                                                                    <CardExpiryElement />
                                                                </LandingPageStyle>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                {
                                                    this.state.isPaymentButton ?
                                                        <Box
                                                            className={classes.boxStyle}
                                                            style={{ marginTop: 'auto' }}>
                                                            <Button
                                                                variant="contained"
                                                                fullWidth
                                                                type="submit"
                                                                data-testId={"openPaymentModal"}
                                                                className={classes.buttonStyle}
                                                            >
                                                                Pay ${this.state.totalAmount}
                                                            </Button>
                                                        </Box>
                                                        :
                                                        <Box className={classes.boxStyle} >
                                                            <Button
                                                                variant="contained"
                                                                fullWidth
                                                                className={classes.buttonStyle}
                                                                data-testId={"proceedBtn"}
                                                            >
                                                                {configJSON.proceed}
                                                            </Button>
                                                        </Box>
                                                }
                                            </Box>
                                        </form>
                                    )}
                                </ElementsConsumer>
                            </Elements>
                        </Grid>
                    </Grid>

                    <Dialog open={this.state.isModalOpen}
                        maxWidth="md"
                        PaperProps={{ className: classes.dialogStyles }}
                        onClose={this.handleModalClose}
                        data-testId={"openModal"}
                    >
                        <Grid container>
                            <Grid item xs={12} className={classes.cancelGridStyle}>
                                <img
                                    data-testid={"closeModal"}
                                    src={closeIcon}
                                    onClick={this.handleModalClose}
                                    className={classes.closeIconStyle}
                                />
                            </Grid>
                        </Grid>

                        <Box className={classes.dialogBoxStyle}>
                            <img
                                src={rightTickIcon}
                                className={classes.rightTickIcon}
                            />
                            <Typography className={classes.welcomeFontStyle}>{configJSON.congratulation}</Typography>
                            <Typography className={classes.welcomeFontStyle3}>{configJSON.addListingScuccess}</Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.buttonStyle}
                                data-testid={"gotoaccount"}
                                onClick={this.handleVendorAccountNavigation}
                            >
                                {configJSON.backtoaccounts}
                            </Button>
                        </Box>
                    </Dialog>

                    {/* Error Dialog */}
                    <Dialog
                        open={this.state.isPaymentFailed}
                        maxWidth="md"
                        PaperProps={{ className: classes.dialogStyles }}
                        onClose={this.handleFailedModalClose}
                        data-testId={"openErrorModal"}
                    >
                        <Grid container>
                            <Grid item xs={12} className={classes.cancelGridStyle}>
                                <img
                                    data-testid={"closeinstruction"}
                                    src={closeIcon}
                                    onClick={this.handleFailedModalClose}
                                    className={classes.closeIconStyle}
                                />
                            </Grid>
                        </Grid>

                        <Box className={classes.dialogBoxStyle_2}>
                            <img
                                src={deleteImage}
                                className={classes.rightTickIcon}
                            />
                            <Typography className={classes.welcomeFontStyle}>{configJSON.paymentFailed}</Typography>
                            <Typography className={classes.welcomeFontStyle2}>{configJSON.paymentFailedText}</Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.buttonStyle}
                                onClick={this.handleFailedModalClose}
                            >
                                {configJSON.tryAgain}
                            </Button>
                            <Typography
                                className={classes.tryAgainFont}
                                onClick={this.handleVendorAccountNavigation}
                            > {configJSON.backtoaccounts}</Typography>
                        </Box>
                    </Dialog>
                </Box>
            </Box >
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const LandingPageStyle = styled(Box)({
    borderBottom: "1px solid #DDDDDD",
    borderRadius: "4px",
    outline: "none",
    padding: "14px 0px",
    color: "#DDDDDD",
    fontWeight: 400,
    '&:hover': {
        border: "unset",
    },
    '&:active': {
        border: "unset",
    },
    '&:focus': {
        border: "unset",
    }

})

export const userStyles = () => ({
    root: {
        backgroundColor: '#f8f2ff',
        flexGrow: 1,
    },
    backdrop: {
        color: '#fff',
        zIndex: 1200,
    },
    detailsBoxStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        gap: '8%',
    },

    boxStyle1: {
        height: '389px',
        background: '#FFFFFF',
    },

    detailsBox: {
        display: 'flex',
    },

    boxStyle2: {
        background: '#FFFFFF',
        height: '389px',
    },

    detailsBoxStyles: {
        paddingBottom: '5%',
        paddingTop: '5%',
        marginTop:"5%",
        "@media(max-width: 1200px)": {
            marginTop:"7%",
          },
          "@media(max-width: 1000px)": {
            marginTop:"9%",
          },
          "@media(max-width: 950px)": {
            marginTop:"9%",
          },          
          "@media(max-width: 820px)": {
            marginTop:"10%",
          },
          "@media(max-width: 760px)": {
            marginTop:"10%",
          },
          "@media(max-width: 630px)": {
            marginTop:"11%",
          },
          "@media(max-width: 480px)": {
            marginTop:"10%",
        },
    },

    gridContainerStyle: {
        gap: '7%',
        width: 'auto'
    },

    headingStyle: {
        fontSize: '22px',
        fontWeight: 600,
        padding: '20px',
        color: '#000000',
        fontFamily: 'Poppins',
    },

    errorStyle: {
        fontSize: '14px',
        fontWeight: 400,
        color: 'red',
        fontFamily: 'Poppins',
    },

    contentBoxStyle: {
        padding: '25px',
        marginTop: '2%',
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '15px'
    },

    headingStyle1: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#000000',
        fontFamily: 'Poppins',
        lineHeight: '26px'
    },

    detailsTextStyle: {
        marginLeft: '4%',
        fontSize: '24px',
        fontWeight: 400,
        color: '#262424',
        fontFamily: 'Poppins',
        padding: '10px'
    },

    totalBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '25px 20px 10px 20px',
    },

    totalPriceTextStyle: {
        fontSize: '24px',
        fontWeight: 700,
        color: '#000000',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '18px',
        }
    },

    totalPriceTextStyle3: {
        fontSize: '23px',
        fontWeight: 700,
        color: '#000000',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '18px',
        }
    },

    totalBoxStyle2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 20px 10px 20px',
    },

    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

    cuponDiscpuntBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px 20px 10px 20px',
    },

    totalPriceTextStyle2: {
        fontSize: '24px',
        fontWeight: 400,
        color: '#8B8B8B',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '18px',
        }
    },

    cuponBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px',
        margin: '15px 27px 20px 27px',
        border: '2px dashed #1B6BCC'
    },

    cuponTextStyle: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#1B6BCC',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '16px',
        }
    },

    removeTextStyle: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#FF0000',
        fontFamily: 'Poppins',
        cursor: 'pointer'
    },

    tryAgainFont: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
    },

    cuponTextStyle2: {
        fontSize: '20px',
        fontWeight: 500,
        color: '#999999',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '16px',
        }
    },

    totalBoxStyle3: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10% 20px 10px 20px',
    },

    paymentText: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#000000',
        fontFamily: 'Poppins',
    },

    paymentBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',

        "@media (min-width: 300px) and (max-width: 950px)": {
            display: 'flex',
            flexDirection: 'column'
        }
    },

    gridItemStyle: {
        marginTop: '15px',
        padding: '8px'
    },

    closeIconStyle: {
        cursor: 'pointer',
        height: '32px',
        width: '32px',
        padding: '10px'
    },

    boxStyle: {
        padding: '30px',
    },

    dividerStyle: {
        backgroundColor: "#707070",
        width: '500px',
        marginTop: '15px',
        opacity: "10%",

        "@media (min-width: 300px) and (max-width: 950px)": {
            width: '300px',
        }
    },

    buttonStyle: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        cursor:"pointer"
    },

    root1: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        borderRadius: "50%",
        width: 20,
        height: 20,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    checkedIcon: {
        borderRadius: "50%",
        backgroundColor: "#8833FF",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 20,
            height: 20,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: "#8833FF"
        }
    },

    TextFieldStyle: {
        marginLeft: '25px',
        width: '75px',
    },

    TextFieldStyle2: {
        width: '40%',
    },

    cardBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        width: '100%'
    },

    cardIconStyle: {
        height: '48px',
        width: '48px',
        verticalAlign: 'middle',
        marginLeft: '28px'
    },
    cardIconStyle2: {
        height: '26px',
        width: '34px',
        verticalAlign: 'middle',
        marginLeft: '28px'
    },

    label1Font: {
        fontSize: '20px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#3A3A3A',
    },

    label2Font: {
        fontSize: '17px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#9B9B9B',
        marginLeft: '8px'
    },

    label3Font: {
        fontSize: '21px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#3A3A3A',
    },

    labelBoxStyle: {
        display: 'grid',
        gridTemplateColumns: '5fr 1fr',
        alignItems: 'center'
    },

    textfeildBox: {
        marginLeft: '25px',
        width: '75%',
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '20px',
        marginTop: "10px",
    },
    nameTextfield:{
        "& input::placeholder": {
            color: 'black'
        },
    },

    cvvBoxStyle: {
        display: 'flex',
        gap: '20px'
    },

    dialogStyles: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    dialogBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px'
    },

    dialogBoxStyle_2: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        width: '70%'
    },

    rightTickIcon: {
        height: '75px',
        width: '75px'
    },

    welcomeFontStyle: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    welcomeFontStyle2: {
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Inter',
        lineHeight: '26px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
    welcomeFontStyle3: {
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '26px',
        textAlign: 'center' as const,
        color: '#0F172A'
    }

});

export default withStyles(userStyles)(ListingCheckOutPage)
// Customizable Area End